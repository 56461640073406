import axios from "axios";

export const fetchUsers = async () => {
  try {
    // eslint-disable-next-line no-undef
    const response = await axios.get(`${process.env.REACT_APP_HOST}/users`);
    return response.data;
  } catch (error) {
    return error.response;
  }
};

export const fetchUser = async email => {
  try {
    // eslint-disable-next-line no-undef
    const response = await axios.get(
      `${process.env.REACT_APP_HOST}/users/${email}`
    );
    return response.data;
  } catch (error) {
    return error.response;
  }
};

export const addUser = async values => {
  const data = {
    userId: values.email,
  };

  const headers = {
    "Content-Type": "application/json",
  };

  try {
    // eslint-disable-next-line no-undef
    const response = await axios.post(
      `${process.env.REACT_APP_HOST}/users`,
      data,
      {
        headers: headers,
      }
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const createUser = async values => {
  const headers = {
    "Content-Type": "application/json",
  };

  try {
    // eslint-disable-next-line no-undef
    const response = await axios.post(
      `${process.env.REACT_APP_HOST}/users/${values.email}`,
      {
        headers: headers,
      }
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const updateUser = async email => {
  const data = {
    activated: true,
  };

  const headers = {
    "Content-Type": "application/json",
  };

  try {
    // eslint-disable-next-line no-undef
    const response = await axios.put(
      `${process.env.REACT_APP_HOST}/users/${email}`,
      data,
      {
        headers: headers,
      }
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const deleteUser = async email => {
  try {
    // eslint-disable-next-line no-undef
    const response = await axios.delete(
      `${process.env.REACT_APP_HOST}/users/${email}`
    );
    return response.data;
  } catch (error) {
    return error.response;
  }
};

export default { fetchUsers, fetchUser, addUser, createUser, deleteUser };
