import firebase from "firebase/app";
import "firebase/auth";

const {
  REACT_APP_API_KEY: apiKey,
  REACT_APP_AUTH_DOMAIN: authDomain,
  REACT_APP_DATEABASE_URL: databaseURL,
  REACT_APP_PROJECT_ID: projectId,
  REACT_APP_STORAGE_BUCKET: storageBucket,
  REACT_APP_MESSAGESENDER_ID: messagingSenderId,
} = process.env;
var config = {
  apiKey,
  authDomain,
  databaseURL,
  projectId,
  storageBucket,
  messagingSenderId,
};

firebase.initializeApp(config);
export default firebase;
