import React, { Component } from "react";
import { Circle } from "better-react-spinkit";
import { Modal } from "react-bootstrap";
import * as Sentry from "@sentry/browser";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { isEmpty } from "lodash";
import firebaseApp from "../../resources/firebaseApp";
import ErrorModal from "../../components/ErrorModal/ErrorModal";
import Header from "../../components/Header/Header";
import ConfirmationModal from '../../components/ConfirmationModal/ConfirmationModal';
import { fetchUsers, addUser, fetchUser, createUser, deleteUser } from "../../utils/users";

export default class Admin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      adminData: [],
      error: false,
      errorMsg: "",
      loading: false,
      show: false,
      adminAccount: false,
      confirmation: false,
      confirmationMsg: "",
      userToDelete: ''
    };
  }

  errorModalClose = () =>
    this.setState({
      error: false,
    });

  confirmationModalClose = () =>
    this.setState({
      confirmation: false,
    });

  handleShow = () => this.setState({ show: true });
  handleClose = () => this.setState({ show: false, loading: false });

  // fetch users
  users = async () => {
    await fetchUsers()
      .then(res => {
        if (res.status && res.status !== 200) {
          return this.setState({
            errorMsg: res.statusText,
            error: true,
            loading: false,
            show: false,
          });
        }
        this.setState({ adminData: res });
      })
      .catch(error => {
        console.log("error--->", error);
      });
    this.handleClose();
  };

  // fetch user
  getUser = async email => {
    await fetchUser(email)
      .then(res => {
        if ((res.status && res.status !== 200) || !res.admin) {
          return this.setState({
            error: true,
            errorMsg:
              "You do not have permissions to view this page. Please contact the website administrator.",
          });
        }

        this.setState({
          adminAccount: res.admin,
        });
      })
      .catch(error => {
        console.log("error--->", error);
        this.setState({
          errorMsg: error.message,
          error: true,
          loading: false,
          show: false,
        });
        return true;
      });
  };

  componentDidMount() {
    this.setSentryContext();

    // check if signed in user is admin
    const userUid = localStorage.getItem("userEmail");

    if (userUid) {
      firebaseApp.auth().onAuthStateChanged(user => {
        this.getUser(user.email);
      });
    }

    this.users();

    this.setState({ error: false, errorMsg: "" });

    try {
      this.setState({ loading: false });
    } catch (e) {
      console.log("error ---->", e);
    }
  }

  setSentryContext = () => {
    const {
      currentUser: { email },
    } = firebaseApp.auth();
    Sentry.configureScope(scope => {
      scope.setExtra("email", email);
    });
  };

  initialValues = {
    email: "",
  };

  validationObject = Yup.object({
    email: Yup.string()
      .typeError("Please enter a string")
      .email("Invalid email address")
      .required("Required"),
  });

  handleAddUser = async (values, formik) => {
    await addUser(values)
      .then(res => {
        console.log("res222", res);
        if (res.status && res.status !== 200) {
          return this.setState({
            errorMsg: res.statusText,
            error: true,
            loading: false,
            show: false,
          });
        }
      })
      .catch(error => {
        console.log("error 222 --->", error);
      });

    setTimeout(() => {
      this.users();
    }, 1000);
  };

  handleCreateUser = async values => {
    await createUser(values)
      .then(res => {
        console.log("res111", res);
        if (res.status && res.status !== 200) {
          return this.setState({
            errorMsg: res.data.message,
            error: true,
            loading: false,
            show: false,
          });
        }

        this.handleAddUser(values);
      })
      .catch(error => {
        console.log("error111", error);
        return this.setState({
          errorMsg: error,
          error: true,
          loading: false,
          show: false,
        });
      });
  };

  handleSubmit = (values, formik) => {
    this.setState({
      loading: true,
    });
    this.handleCreateUser(values);
  };

  deleteUserAccount = async () => {
    this.setState({
      loading: true,
    })
    await deleteUser(this.state.userToDelete)
      .then(res => {
        this.users();
      })
      .catch(error => {
        console.log("error--->", error);
        this.setState({
          errorMsg: error.message,
          error: true,
          loading: false,
          show: false,
        });
        return true;
      });
  }

  // delete user profile & user doc
  handleDeleteUser = async (email) => {
    this.setState({
      confirmation: true,
      userToDelete: email,
      confirmationMsg: `Are you sure you want to delete user ${email}`
    });
  }

  render() {
    return (
      <div className="admin">
        {/* header */}
        <Header />

        {this.state.adminAccount && (
          <>
            <main>
              <div className="container">
                <div className="page-title row">
                  <h1 className="col-6 vertical-center-left">Users</h1>
                  <div className="col-6 btn-container">
                    <button
                      type="button"
                      className="btn btn-primary float-right"
                      onClick={this.handleShow}
                    >
                      Add User
                    </button>
                  </div>
                </div>
                <table border="1">
                  <thead>
                    <tr>
                      <th>Email</th>
                      <th className="text-center">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!isEmpty(this.state.adminData) &&
                      this.state.adminData.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{item.email}</td>
                            <td className="text-center">
                              <button onClick={() => this.handleDeleteUser(item.email)} className="btn btn-link btn-delete"></button>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </main>

            <Modal show={this.state.show} onHide={this.handleClose} centered>
              <Modal.Header closeButton>
                <Modal.Title>Add User</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Formik
                  initialValues={this.initialValues}
                  validationSchema={this.validationObject}
                  onSubmit={this.handleSubmit}
                >
                  {formik => {
                    return (
                      <Form>
                        <div className="form-group">
                          <label htmlFor="email">Email</label>
                          <Field
                            type="email"
                            className="form-control"
                            name="email"
                          />
                          <div className="error">
                            <ErrorMessage name="email" />
                          </div>
                        </div>
                        <div className="btn-row">
                          <button
                            type="submit"
                            className="btn btn-secondary float-left"
                            onClick={this.handleClose}
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            className="btn btn-primary float-right"
                            onClick={() => {
                              formik.handleSubmit();
                            }}
                          >
                            Submit
                          </button>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </Modal.Body>
              <Modal.Footer className="p-0" />
            </Modal>
          </>
        )}

        {this.state.loading && (
          <div className="overlay">
            <Circle size={200} color="#CC2400" />
          </div>
        )}

        {this.state.error && (
          <ErrorModal
            onHide={this.errorModalClose}
            errorMsg={this.state.errorMsg}
          />
        )}

        {this.state.confirmation && (
          <ConfirmationModal
            onHide={this.confirmationModalClose}
            errorMsg={this.state.confirmationMsg}
            actionHandler={this.deleteUserAccount}
          />
        )}
      </div>
    );
  }
}
