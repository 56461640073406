import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";

class ErrorModal extends Component {
  render() {
    const { onHide, errorMsg } = this.props;

    return (
      <Modal
        show={true}
        onHide={() => onHide()}
        className="error-modal"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Error</Modal.Title>
        </Modal.Header>

        <Modal.Body>{errorMsg}</Modal.Body>

        <Modal.Footer>
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => onHide()}
          >
            Continue
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}

ErrorModal.propTypes = {
  errorMsg: PropTypes.string,
  onHide: PropTypes.func,
};

export default ErrorModal;
