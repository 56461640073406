import React, { Component } from "react";
import { Link } from "react-router-dom";
import firebaseApp from "../../resources/firebaseApp";
import { fetchUser } from "../../utils/users";

export default class Header extends Component {
  state = {
    adminAccount: false,
    userEmail: "",
  };

  signout = () => {
    localStorage.removeItem("userEmail");
    firebaseApp.auth().signOut();
  };

  // fetch user
  getUser = async email => {
    await fetchUser(email)
      .then(res => {
        this.setState({
          adminAccount: res.admin,
        });
      })
      .catch(error => {
        console.log("error--->", error);
      });
  };

  componentDidMount() {
    const userUid = localStorage.getItem("userEmail");
    if (userUid) {
      firebaseApp.auth().onAuthStateChanged(user => {
        this.setState({
          userEmail: user.email,
        });
        this.getUser(user.email);
      });
    }
  }

  render() {
    return (
      <header>
        <div className="container no-print">
          <div className="row">
            <div className="col-6">
              <Link to="/invoice">
                <img
                  src="/images/logo.png"
                  alt="Data Group Exchange"
                  height="40px"
                />
              </Link>
            </div>
            <div className="col-6 btn-container">
              {this.state.adminAccount && (
                <>
                  <Link to="/admin" className="btn btn-link">
                    Admin Page
                  </Link>
                  <span className="devider">|</span>
                </>
              )}
              <span className="user-details">{this.state.userEmail}</span>
              <button className="btn btn-primary" onClick={this.signout}>
                Sign Out
              </button>
            </div>
          </div>
        </div>
      </header>
    );
  }
}
