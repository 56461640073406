import React, { Component } from "react";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import "./App.scss";
import Invoice from "./screens/Invoice/Invoice";
import Admin from "./screens/Admin/Admin";
import Login from "./screens/Login/Login";
import firebaseApp from "./resources/firebaseApp";
import NotFound from "./screens/NotFound/NotFound";
import ErrorBoundary from "./components/ErrorBoundary";
import * as Sentry from "@sentry/browser";
class App extends Component {
  state = {
    isSignedIn: null, // Local signed-in state.
  };

  async componentDidMount() {
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DNS,
      environment: process.env.REACT_APP_ENVIRONMENT,
      attachStacktrace: true,
      release: process.env.REACT_APP_SENTRY_RELEASE,
    });
    this.unregisterAuthObserver = await firebaseApp
      .auth()
      .onAuthStateChanged(user => this.setState({ isSignedIn: !!user }));
  }

  // Make sure we un-register Firebase observers when the component unmounts.
  componentWillUnmount() {
    this.unregisterAuthObserver();
  }

  ProtectedRoutes = ({ component: Component, ...rest }) => {
    return (
      <Route
        {...rest}
        render={props => {
          if (this.state.isSignedIn == null) {
            return <div />;
          }
          if (this.state.isSignedIn) {
            return <Component {...props} />;
          }

          return <Login />;
        }}
      />
    );
  };

  render() {
    const { ProtectedRoutes } = this;
    return (
      <ErrorBoundary>
        <Router>
          <Switch>
            <ProtectedRoutes path="/invoice/" component={Invoice} />
            <ProtectedRoutes path="/admin/" component={Admin} />
            <ProtectedRoutes path="/" component={Invoice} />
            <Route component={NotFound} />
          </Switch>
        </Router>
      </ErrorBoundary>
    );
  }
}

export default App;
