import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";

class ConfirmationModal extends Component {
  render() {
    const { onHide, errorMsg, actionHandler } = this.props;

    return (
      <Modal
        show={true}
        onHide={() => onHide()}
        className="confirmation-modal"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Are you sure?</Modal.Title>
        </Modal.Header>

        <Modal.Body>{errorMsg}</Modal.Body>

        <Modal.Footer>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => onHide()}
          >
            No
          </button>
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => {onHide(); actionHandler()}}
          >
            Yes
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}

ConfirmationModal.propTypes = {
  errorMsg: PropTypes.string,
  onHide: PropTypes.func,
  actionHandler: PropTypes.func
};

export default ConfirmationModal;
