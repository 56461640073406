import React, { Component } from "react";
import axios from "axios";
import firebaseApp from "../../resources/firebaseApp";
import html2pdf from "html2pdf.js";
import qs from "query-string";
import ErrorModal from "../../components/ErrorModal/ErrorModal";
import Header from "../../components/Header/Header";
import { Circle } from "better-react-spinkit";
import { Col, Row } from "react-bootstrap";
import * as Sentry from "@sentry/browser";
import {
  PARCEL_NOT_FOUND,
  NOT_AUTHORIZED,
  APPLICATION_ERROR,
} from "../../components/ErrorModal/errorMessage";

export default class Invoice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      invoiceData: "",
      error: false,
      loading: false,
      errorMsg: "",
    };
  }

  setSentryContext = () => {
    const {
      currentUser: { email },
    } = firebaseApp.auth();
    Sentry.configureScope(scope => {
      scope.setExtra("email", email);
    });
  };

  getServerError = e => {
    const {
      response: { status },
    } = e;

    if (status === 401) {
      return NOT_AUTHORIZED;
    } else if (status > 500) {
      return APPLICATION_ERROR;
    } else {
      return PARCEL_NOT_FOUND;
    }
  };

  async componentDidMount() {
    const { REACT_APP_HOST: host } = process.env;
    this.setSentryContext();
    this.setState({ error: false, loading: true, errorMsg: "" });
    try {
      const token = await firebaseApp.auth().currentUser.getIdToken();
      const { parcelCode } = qs.parse(
        window.location.hash.replace("/invoice?", "")
      );
      const { data } = await axios.get(
        `${host}/invoice?parcelCode=${parcelCode}`,
        {
          headers: { authorization: "Bearer " + token },
        }
      );
      this.setState({ loading: false });
      this.setState({ invoiceData: data });
      const source = window.document.getElementById("invoice-container");

      const parcels = window.document.getElementsByClassName(
        "list-table-items"
      );
      const pagebreak =
        parcels.length > 3
          ? { mode: "avoid-all", before: "#page2" }
          : { mode: "avoid-all" };

      html2pdf()
        .from(source)
        .set({
          margin: [0, 0, -4, 0],
          filename: `Invoice-${parcelCode}`,
          pagebreak,
          html2canvas: { logging: false, scale: 1 },
          jsPDF: {
            hotfixes: ["px_scaling"],
            unit: "px",
            format: "a4",
            orientation: "portrait",
            putOnlyUsedFonts: true,
          },
        })
        .save()
        .catch(error => {
          // prettier-ignore
          console.log("Catch internal library errors which get escaped", error);
        });
    } catch (e) {
      const errorMsg = e.response ? this.getServerError(e) : APPLICATION_ERROR;
      this.setState({ error: true, loading: false, errorMsg });
    }
  }

  getInvoiceData = invoiceData => {
    return (
      <div
        id="js-invoice"
        style={styles.invoice}
        dangerouslySetInnerHTML={{ __html: invoiceData }}
      />
    );
  };

  getLoader = () => {
    return (
      <Row className="justify-content-md-center" style={styles.loader}>
        <Col md="auto">
          <Circle size={200} color="#CC2400" />
        </Col>
      </Row>
    );
  };

  errorModalClose = () =>
    this.setState({
      error: false,
    });

  loadPage = () => {
    const { invoiceData, error, errorMsg } = this.state;

    if (error) {
      return <ErrorModal errorMsg={errorMsg} onHide={this.errorModalClose} />;
    }

    return this.getInvoiceData(invoiceData);
  };

  render() {
    const { loading } = this.state;
    return (
      <div>
        <Header />
        <main>
          <div className="container">
            {loading ? this.getLoader() : this.loadPage()}
          </div>
        </main>
      </div>
    );
  }
}

const styles = {
  loader: {
    paddingTop: "50px",
  },
  invoice: {
    marginLeft: 25,
  },
};
