import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Form, Alert, Modal } from "react-bootstrap";
import { Formik, Form as FormikForm, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import firebaseApp from "../../resources/firebaseApp";
import { fetchUser, updateUser } from "../../utils/users";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      pass: "",
      error: false,
      errorModal: false,
      show: false,
      emailSent: false,
      errorMsg: "",
      loading: false,
    };
  }

  onSubmit = async e => {
    e.preventDefault();
    try {
      const { email, pass } = this.state;
      await firebaseApp
        .auth()
        .signInWithEmailAndPassword(email, pass)
        .then(userDetails => {
          localStorage.setItem("userEmail", userDetails.user.uid);
        });
    } catch (err) {
      this.setState({ error: true });
    }
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value, error: false });
  };

  initialValues = {
    email: "",
  };

  validationObject = Yup.object({
    email: Yup.string()
      .typeError("Please enter a string")
      .email("Invalid email address")
      .required("Required"),
  });

  handleUpdateUser = async email => {
    await updateUser(email)
      .then(res => {
        if (res.status && res.status !== 200) {
          return this.setState({
            errorMsg: res.statusText,
            error: true,
            loading: false,
            show: false,
          });
        }
        this.setState({ emailSent: true });
      })
      .catch(error => {
        console.log("error --->", error);
      });
  };

  actionCodeSettings = {
    url: `${process.env.REACT_APP_WEB_HOST}/#/invoice`,
    // url: 'http://localhost:3000/#/invoice',
  };

  handleSubmit = async (values, formik) => {
    await firebaseApp
      .auth()
      .sendPasswordResetEmail(values.email, this.actionCodeSettings)
      .then(() => {
        this.handleUpdateUser(values.email);
      })
      .catch(error => {
        this.setState({
          errorMsg: error.message,
          error: true,
          loading: false,
          show: false,
        });
      });
  };

  handleClose = () => this.setState({ show: false });

  // fetch user
  getUser = async email => {
    await fetchUser(email)
      .then(res => {
        if (res.status && res.status !== 200) {
          return this.setState({
            error: true,
            errorMsg: "Please check the email address you have provided.",
          });
        }

        if (!res.activated) {
          this.setState({
            show: true,
          });
        }
      })
      .catch(error => {
        this.setState({
          errorMsg: error.message,
          error: true,
          loading: false,
          show: false,
        });
        return true;
      });
  };

  checkAccount = e => {
    const email = e.target.value;
    this.getUser(email);
  };

  render() {
    const { error } = this.state;
    if (!this.props.isSignedIn) {
      return (
        <div className="login">
          <div className="row full-height">
            <div className="col-4 offset-4 vertical-center">
              <div className="content-container">
                <h4>Login</h4>
                <Form onSubmit={this.onSubmit}>
                  {error && (
                    <Alert variant="danger">
                      Either email or password is incorrect
                    </Alert>
                  )}
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control
                      name="email"
                      type="email"
                      placeholder="Enter email"
                      value={this.state.email}
                      onChange={this.onChange}
                      onBlur={this.checkAccount}
                      autoComplete="off"
                      required={true}
                    />
                  </Form.Group>

                  <Form.Group controlId="formBasicPassword">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Password"
                      name="pass"
                      value={this.state.pass}
                      onChange={this.onChange}
                      autoComplete="off"
                      required={true}
                    />
                  </Form.Group>
                  <div className="btn-row">
                    <button
                      type="button"
                      className="btn btn-link float-left"
                      onClick={() => this.setState({ show: true })}
                    >
                      Forgot Password
                    </button>
                    <button
                      className="btn btn-primary float-right"
                      type="submit"
                    >
                      Submit
                    </button>
                  </div>
                </Form>
              </div>
            </div>
          </div>

          <Modal show={this.state.show} onHide={this.handleClose} centered>
            <Modal.Header closeButton>
              <Modal.Title>Forgot Password</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {!this.state.emailSent && (
                <Formik
                  initialValues={this.initialValues}
                  validationSchema={this.validationObject}
                  onSubmit={this.handleSubmit}
                >
                  {formik => {
                    return (
                      <FormikForm>
                        <div className="form-group">
                          <label htmlFor="email">Enter Email</label>
                          <Field
                            type="email"
                            className="form-control"
                            name="email"
                          />
                          <div className="error">
                            <ErrorMessage name="email" />
                          </div>
                        </div>
                        <div className="btn-row">
                          <button
                            type="button"
                            className="btn btn-secondary float-left"
                            onClick={this.handleClose}
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            className="btn btn-primary float-right"
                            onClick={() => formik.handleSubmit()}
                          >
                            Submit
                          </button>
                        </div>
                      </FormikForm>
                    );
                  }}
                </Formik>
              )}

              {this.state.emailSent && (
                <>
                  <p>
                    A password reset email has been sent to your email address,
                    please follow the link mentioned in it.
                  </p>
                  <button
                    type="button"
                    className="btn btn-primary float-right"
                    onClick={this.handleClose}
                  >
                    Back to Login
                  </button>
                </>
              )}
            </Modal.Body>
            <Modal.Footer />
          </Modal>
        </div>
      );
    }
  }
}
export default withRouter(Login);
