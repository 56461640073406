import React, { Component } from "react";
import { Row, Col, Modal } from "react-bootstrap";

class NotFound extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      pass: "",
      error: false,
    };
  }

  initialState = () => {
    this.setState({
      email: "",
      pass: "",
      error: false,
    });
  };

  render() {
    return (
      <div>
        <Row>
          <Col xs={{ span: 4, offset: 4 }}>
            <Modal.Dialog>
              <Modal.Header style={styles.header}>
                <Modal.Title>Error</Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <p>Page Not Found</p>
              </Modal.Body>
            </Modal.Dialog>
          </Col>
        </Row>
      </div>
    );
  }
}
const styles = {
  header: {
    background: "#cc0000",
    color: "white",
  },
};

export default NotFound;
